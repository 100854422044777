import { authentication, revoke } from "@/api";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export type authData = {
    access_token: string;
    refresh_token: string;
    expires_in: number;
};

@Module({ name: 'Auth' })
export default class AuthStore extends VuexModule {
    /* States */

    private accessToken = ''
    private refreshToken = ''
    private expiresIn = 0

    /* Getters */

    public get is_login(): boolean {
        return !!this.expiresIn && Date.now() < this.expiresIn;
    }

    public get access_token(): string {
        return this.accessToken
    }

    public get refresh_token(): string {
        return this.refreshToken
    }

    public get expires_in(): number {
        return this.expiresIn
    }

    /* Mutations */

    @Mutation
    setAuthentication(data: authData): void {
        this.accessToken = data.access_token
        this.refreshToken = data.refresh_token
        this.expiresIn = Date.now() + data.expires_in * 1000;
    }

    /* Actions */

    @Action({ commit: "setAuthentication", rawError: true })
    async doLogin(data: { username: string; password: string }): Promise<authData> {
        const resp = await authentication(data)
        const { access_token, refresh_token, expires_in } = resp.data
        return { access_token, refresh_token, expires_in }
    }

    @Action({ commit: "setLogin", rawError: true })
    async doLogout(): Promise<authData> {
        await revoke(this.access_token);
        const empty = {
            access_token: "",
            refresh_token: "",
            expires_in: 0,
        };
        return empty;
    }
}