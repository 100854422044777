import Vue from 'vue'
import Vuex from 'vuex'
import CreatePersisted from "vuex-persistedstate";

import TokenManager from "./plugins/token_inject";

import AuthModule from "./module/auth.module";
import NotifModule from "./module/notif.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    AuthModule, NotifModule
  },
  plugins: [CreatePersisted(), TokenManager]
})
