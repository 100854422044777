






































import router from "@/router";
import { Auth, Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({ name: 'login-index' })
export default class LoginIndex extends Vue {
  /* Variables */

  formData = {
    username: "",
    password: ""
  }

  loading = false

  /* Computed */



  /* Methods */

  async onSubmit(): Promise<void> {
    this.loading = true
    try {
      const resp = await Auth.doLogin(this.formData)
      if (resp) router.replace({ name: 'Dashboard' })
    } catch (error) {
      Notif.notif_api_error(error)
    } finally {
      this.loading = false
    }
  }

  /* Life-cycle Methods */

  created(): void {
    if (Auth.is_login) {
      router.replace({ name: "Dashboard" });
    }
  }

}
