

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import ApexCharts from "vue-apexcharts";
import { Notif } from "@/store";
import { get_counter_date, get_counter_total, get_site_total } from "@/api";
import { ApexOptions } from "apexcharts";
import DateRange from "@/components/DateRange.vue";
import { array_sum } from "@/shared/utils";
import config from "@/shared/config";

@Component({ name: 'dashboard-index', components: { ApexCharts, DateRange } })
export default class DashboardIndex extends Vue {
  /* Variables */

  TodayTotalCharts = {
    series: []
  } as ApexOptions

  WeekTotalCharts = {
    series: []
  } as ApexOptions

  MonthTotalCharts = {
    series: []
  } as ApexOptions

  DailyCharts = {
    series: [{ name: 'Total Queue', data: [] }]
  } as ApexOptions

  dailyFilters = {
    start_date: null as unknown as string,
    end_date: null as unknown as string,
  }

  date = []

  SiteTotalCharts = {
    series: [{ data: [] }]
  } as ApexOptions

  /* Computed */



  /* Methods */

  array_sum = array_sum

  async fetchTotal(): Promise<void> {
    try {
      const resp = await get_counter_total()
      this.TodayTotalCharts = {
        labels: [...resp.data[0].today.map(t => t.counter)],
        series: [...resp.data[0].today.map(t => t.total)]
      }
      this.WeekTotalCharts = {
        labels: [...resp.data[1].thisWeek.map(t => t.counter)],
        series: [...resp.data[1].thisWeek.map(t => t.total)]
      }
      this.MonthTotalCharts = {
        labels: [...resp.data[2].last30days.map(t => t.counter)],
        series: [...resp.data[2].last30days.map(t => t.total)]
      }
    } catch (error) {
      Notif.notif_api_error(error)
    }
  }

  async fetchDaily(): Promise<void> {
    try {
      const resp = await get_counter_date(this.dailyFilters)
      this.DailyCharts = {
        xaxis: { categories: [...resp.data.map(t => t.date)] },
        series: [{ name: 'Total Queue', data: [...resp.data.map(t => t.summary.totalQueue)] }]
      }
    } catch (error) {
      Notif.notif_api_error(error)
    }
  }

  async fetchSiteTotal(): Promise<void> {
    try {
      const resp = await get_site_total()
      let data = [] as { name: string; data: number[] }[]
      let frequency = [] as number[]
      let name = [] as string[]
      name = resp.data[0].content.map(t => t.site)
      name.forEach(it => {
        resp.data.forEach(t => {
          frequency.push(...t.content.filter(f => f.site == it).map(to => to.total))
        })
        data.push({ name: it, data: frequency })
        frequency = []
      })
      this.SiteTotalCharts = {
        xaxis: { categories: resp.data.map(t => t.date) },
        series: [...data]
      }
    } catch (error) {
      Notif.notif_api_error(error)
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchDaily()
    this.fetchTotal()
    this.fetchSiteTotal()
    setInterval(() => {
      this.fetchDaily()
      this.fetchTotal()
      this.fetchSiteTotal()
    }, config.REFRESH_INTERVAL)
  }

  @Watch('dailyFilters.end_date')
  onDateChange(): void {
    this.fetchDaily()
  }

}
