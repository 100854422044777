









import { Component, Vue } from "vue-property-decorator";
import NotificationBar from "@/components/NotificationBar.vue";

@Component({ name: 'basic-layout', components: { NotificationBar } })
export default class BasicLayout extends Vue {

}
