import { AppModule } from '@/shared/types'

import LoginIndex from './index.vue'

const LoginModule: AppModule = {
  rootLink: '/login',
  layout: 'BasicLayout',
  authRequired: false,
  childrenLink: [
    {
      path: '',
      name: 'Login',
      component: LoginIndex,
    },
  ],
}

export default LoginModule
