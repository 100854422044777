


































































































































import { Notif } from '@/store'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { open_export, route_replace, time_format } from '@/shared/utils'
import { get_device, get_logs } from '@/api'
import { deviceType, logsType } from '@/api/api.types'
import DateRange from '@/components/DateRange.vue'
import ButtonTool from '@/components/ButtonTool.vue'
import API from '@/api/api.constant'
import config from '@/shared/config'

const ExportDaily = () => import('./ExportDaily.vue')
const ExportMonthly = () => import('./ExportMonthly.vue')

@Component({
  name: 'queueday-index',
  components: { DateRange, ButtonTool, ExportDaily, ExportMonthly },
})
export default class QueuedayIndex extends Vue {
  /* Variables */

  loading = false

  tableFilter = {
    device_uuid: this.$route.query.device_uuid || null,
    site_uuid: this.$route.query.site_uuid || null,
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    counter: this.$route.query.counter || null,
  }

  date = this.tableFilter.start_date
    ? [this.tableFilter.start_date, this.tableFilter.end_date]
    : []

  tableData = [] as logsType['data']['_embedded']['queue_log']

  deviceList = [] as deviceType['data']['_embedded']['queue_device']

  // siteList = [] as siteType['data']['_embedded']['queue_site']

  counterList = ['0', '1'] // Temporary

  page = {
    page_count: 1,
    page_size: 1,
    page: 1,
  }

  exportAPI = [API.COUNTER_EXPORT, API.SUMMARY_EXPORT]

  exportDaily = null as string | null

  exportMonthly = null as string | null

  /* Computed */

  public get tableHeader(): DataTableHeader[] {
    return [
      { text: 'Num', value: 'number' },
      { text: 'Counter Num', value: 'counterNumber' },
      { text: 'Reserved', value: 'reservedTime' },
      { text: 'Called', value: 'calledTime' },
      { text: 'End', value: 'endTime' },
      { text: 'Waiting Time', value: 'waitingTime' },
      { text: 'Processing Time', value: 'processingTime' },
      // { text: "Site", value: "device.site.name" },
      { text: 'Device', value: 'device.name' },
    ]
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    this.loading = true

    if (toPage == 0) toPage = 1

    try {
      const resp = await get_logs({ ...this.tableFilter, page: toPage })
      const { page, page_count, page_size } = resp.data
      this.page = { page, page_count, page_size }
      this.tableData = resp.data._embedded.queue_log
      this.tableData.forEach((t) => {
        t.reservedTime = time_format(t.reservedTime)
        t.calledTime = t.calledTime && time_format(t.calledTime)
        t.endTime = t.endTime && time_format(t.endTime)
      })
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage })
    } catch (error) {
      Notif.notif_api_error(error)
    } finally {
      this.loading = false
    }
  }

  async fetchDevice(): Promise<void> {
    try {
      const resp = await get_device({ limit: -1 })
      this.deviceList = resp.data._embedded.queue_device
    } catch (error) {
      Notif.notif_api_error(error)
    }
  }

  // async fetchSite(): Promise<void> {
  //   try {
  //     const resp = await get_site({ limit: -1 })
  //     this.siteList = resp.data._embedded.queue_site
  //   } catch (error) {
  //     Notif.notif_api_error(error)
  //   }
  // }

  async doExport(type: string): Promise<void> {
    try {
      open_export(`${API.SITES_EXPORT}-${type}`, this.tableFilter)
    } catch (error) {
      Notif.notif_api_error(error)
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData()
    setInterval(this.fetchData, config.REFRESH_INTERVAL)
    this.fetchDevice()
    // this.fetchSite()
  }

  @Watch('page.page')
  onPageChange(now: number): void {
    if (now) this.fetchData()
  }
}
