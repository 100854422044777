const config = {

    // Branding
    APP_NAME: process.env.VUE_APP_NAME,
    APP_TITLE: process.env.VUE_APP_TITLE,
    APP_COPYRIGHT: process.env.VUE_APP_COPYRIGHT,

    // API
    APP_SECRET: process.env.VUE_APP_SECRET,
    APP_ID: process.env.VUE_APP_ID,
    BASE_URL: process.env.VUE_APP_API_URL,

    // OTHERS
    REFRESH_INTERVAL: process.env.VUE_APP_REFRESH_INTERVAL || 60000
}

export default config