const API = {

    // Auth

    OAUTH: 'oauth',
    REVOKE: 'api/v1/authrevoke',

    // Logs

    LOGS: 'api/v1/queue-log',

    // Device

    DEVICE: 'api/v1/queue-device',

    // Sites

    SITES: 'api/v1/queue-site',
    SITES_TOTAL: 'api/v1/total-per-site',

    // Counter

    COUNTER_SITE: 'api/v1/stats-per-site',
    COUNTER_DATE: 'api/v1/stats-per-date',
    COUNTER_TOTAL: 'api/v1/total-per-counter',

    // Exports

    SITES_EXPORT: 'api/v1/export-queue',
    COUNTER_EXPORT: 'api/v1/export-excel-per-counter',
    SUMMARY_EXPORT: 'api/v1/export-excel-report',
}

export default API