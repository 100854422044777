import { AppModule } from "@/shared/types";

import QueueIndex from "./Queue/index.vue";

import TotalQueueIndex from "./TotalQueue/index.vue";

const ReportModule: AppModule = {
    rootLink: '/report',
    rootName: 'Report',
    rootIcon: 'mdi-file-document',
    layout: "SidebarLayout",
    authRequired: true,
    childrenLink: [
        {
            path: 'queue',
            name: 'Queue',
            component: QueueIndex
        },
        {
            path: 'total-queue',
            name: 'Total Queue',
            component: TotalQueueIndex
        }
    ]
}

export default ReportModule;