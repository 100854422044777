import config from "@/shared/config";
import axios, { AxiosResponse } from "axios";
import * as types from "./api.types";
import URL from "./api.constant";

/* Create api */

export const API = axios.create({
    baseURL: config.BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

/* Token */

let interceptor = null as unknown as number;

/* Token - Eject */

export const tokenEject = (): void => {
    if (!interceptor) return;
    API.interceptors.request.eject(interceptor);
    interceptor = null as unknown as number;
};

/* Token - Inject */

export const tokenInject = (access_token: string): void => {
    if (!access_token) return;
    tokenEject();
    interceptor = API.interceptors.request.use((req) => {
        req.headers.Authorization = `Bearer ${access_token}`;
        return req;
    });
};

/* API - Auth */

/* Auth - Login */
export const authentication = async (loginData: { username: string, password: string }): Promise<types.authType> => {
    const data = {
        ...loginData,
        grant_type: "password",
        client_id: config.APP_ID,
        client_secret: config.APP_SECRET,
    };
    const resp: types.authType = await API.post(URL.OAUTH, data);
    return resp;
};

/* Auth - Revoke */
export const revoke = async (access_token: string): Promise<AxiosResponse> => {
    const resp = await API.post(URL.REVOKE, { token_type_hint: "access_token", token: access_token })
    return resp
}

/* API - Logs */

export const get_logs = async (params?: Record<string, any>): Promise<types.logsType> => {
    const resp: types.logsType = await API.get(URL.LOGS, { params })
    return resp
}

/* API - Device */

export const get_device = async (params?: Record<string, any>): Promise<types.deviceType> => {
    const resp: types.deviceType = await API.get(URL.DEVICE, { params })
    return resp
}

/* API - Site */

export const get_site = async (params?: Record<string, any>): Promise<types.siteType> => {
    const resp: types.siteType = await API.get(URL.SITES, { params })
    return resp
}

export const get_site_total = async (params?: Record<string, any>): Promise<types.siteTotalType> => {
    const resp: types.siteTotalType = await API.get(URL.SITES_TOTAL, { params })
    return resp
}

/* API - Counter */

/* Counter - Site */
export const get_counter_site = async (params?: Record<string, any>): Promise<types.counterSiteType> => {
    const resp: types.counterSiteType = await API.get(URL.COUNTER_SITE, { params })
    return resp
}

/* Counter - Date */
export const get_counter_date = async (params?: Record<string, any>): Promise<types.counterDateType> => {
    const resp: types.counterDateType = await API.get(URL.COUNTER_DATE, { params })
    return resp
}

/* Counter - Total */
export const get_counter_total = async (params?: Record<string, any>): Promise<types.counterTotalType> => {
    const resp: types.counterTotalType = await API.get(URL.COUNTER_TOTAL, { params })
    return resp
}