import layout from '@/layout'
import PageModules from '@/views/PageModules'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = []

PageModules.forEach((module) => {
  const route = {
    path: module.rootLink,
    name: module.rootName,
    component: layout[module.layout],
    meta: { authRequired: module.authRequired || false },
    children: module.childrenLink.map(item => item)
  }

  routes.push(route)
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
