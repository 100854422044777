import { tokenEject, tokenInject } from "@/api";
import { Store } from "vuex";

const token_manager = (store: Store<any>): void => {
  let timeOut: number;

  if (store.getters["is_login"]) {
    const { access_token } = store.getters;
    tokenInject(access_token);
  }

  store.subscribe((mutation): void => {
    if (mutation.type === "setAuthentication") {
      if (mutation.payload !== undefined) {
        const { access_token } = mutation.payload;
        tokenInject(access_token);
      } else {
        tokenEject();
        clearTimeout(timeOut);
        localStorage.clear();
      }
    }
  });
};

export default token_manager;
