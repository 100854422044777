
























































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { route_replace } from "@/shared/utils";
import { get_counter_site, get_device, get_site } from "@/api";
import { counterSiteType, deviceType, siteType } from "@/api/api.types";
import DateRange from "@/components/DateRange.vue";

@Component({ name: 'queuecount-index', components: { DateRange } })
export default class QueuecountIndex extends Vue {
  /* Variables */

  tableFilter = {
    device_uuid: this.$route.query.device_uuid || null,
    site_uuid: this.$route.query.site_uuid || null,
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    counter: this.$route.query.counter || null,
  }

  date = this.tableFilter.start_date
    ? [this.tableFilter.start_date, this.tableFilter.end_date]
    : [];

  deviceList = [] as deviceType['data']['_embedded']['queue_device']

  siteList = [] as siteType['data']['_embedded']['queue_site']

  tableData = [] as counterSiteType['data']

  counterList = ['0', '1']  // Temporary

  page = {
    page_count: 1,
    page_size: 1,
    page: 1
  }

  /* Computed */

  public get tableHeader(): DataTableHeader[] {
    return [
      { text: "Site", value: 'site' },
      { text: "Device", value: 'device' },
      { text: "Counter", value: 'counter' },
      { text: "Total Queue", value: 'summary.totalQueue' },
      { text: "Processing Time", value: 'summary.avgProcessingTime' },
      { text: "Waiting Time", value: 'summary.avgWaitingTime' },
    ]
  }

  /* Methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1
    try {
      const resp = await get_counter_site({ ...this.tableFilter, page: toPage });
      this.tableData = resp.data
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage })
    } catch (error) {
      Notif.notif_api_error(error)
    }
  }

  async fetchDevice(): Promise<void> {
    try {
      const resp = await get_device({ limit: -1 })
      this.deviceList = resp.data._embedded.queue_device
    } catch (error) {
      Notif.notif_api_error(error)
    }
  }

  async fetchSite(): Promise<void> {
    try {
      const resp = await get_site({ limit: -1 })
      this.siteList = resp.data._embedded.queue_site
    } catch (error) {
      Notif.notif_api_error(error)
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData()
    this.fetchDevice()
    this.fetchSite()
  }

  @Watch('page.page')
  onPageChange(now: number): void {
    if (now) this.fetchData()
  }

}
