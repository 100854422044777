import { AppModule } from '@/shared/types'

import DashboardIndex from './index.vue'

const DashboardModule: AppModule = {
  rootLink: '/',
  rootName: 'Dashboard',
  rootIcon: 'mdi-view-dashboard',
  layout: 'SidebarLayout',
  authRequired: true,
  childrenLink: [
    {
      path: '/',
      name: 'Dashboard',
      component: DashboardIndex,
    },
  ],
}

export default DashboardModule
